.landing-form {
    width: 400px;
    position: absolute;
    padding: 25px;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -10%, 0px);
    display: none;
    background: white;
    border-radius: 6px;
    box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.55);
}

.landing-form__form {}

.landing-form__login {}

.landing-form hr {
    border: none;
    height: 1px;
    background-color: #DBDBDB;
    margin: 20px 0;
}

.landing-form__form .form-control-select {
    margin: 0;
    margin-bottom: 20px;
}


@media (min-width: 768px) {
    .landing-form {
        display: block;
    }
}