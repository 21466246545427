.landing__body-categories {
    height: auto;
    overflow: auto;
    padding: 2rem 0;
    display: grid;
    max-width: 1300px;
    margin: 0 auto;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.landing__body-logotipos {
    padding-top: 2rem;
}

@media (min-width: 768px) {

    .landing__body-categories {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 992px) {

    .landing__body-categories {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}


@media (min-width: 1350px) {
    .landing__body-categories {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}