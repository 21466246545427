.landing__footer {
  padding-bottom: 2rem;
  max-width: 90%;
  margin: 0 auto;
}
.landing__footer hr {
  border: none;
  height: 1px;
  background-color: #e2e3e8;
}
.landing__footer-text-cnt {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.landing__footer-link-container a,
.landing__footer-link-container a:visited {
  font-weight: 600;
  color: #333333;
  text-decoration: none;
}

@media (min-width: 768px) {
  .landing__footer {
    max-width: 95%;
  }
}

@media (min-width: 992px) {
  .landing__footer {
    max-width: 95%;
  }
}

@media (min-width: 1250px) {
  .landing__footer {
    max-width: 1300px;
  }
}
