.image-card {
    width: 90%;
    height: 150px;
    margin: 0 auto;
    position: relative;
    margin-top: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.image-card__title {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.image-card__title h2 {
    z-index: 0;
    color: white;
}

.image-card__picture {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
}

.image-card__picture img {
    border-radius: 4px;
    object-fit: cover;
    width: 100%;
    height: 150px;
}