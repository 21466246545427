.landing {
    position: static;
    z-index: 2;
}

.landing__container {
    position: relative;
    height: 0;
    padding-bottom: 70%;
}

.landing__header {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 280px;
    transform: translate3d(-50%, -50%, 0);
}

.landing__header h1 {
    color: white;
    font-size: 2rem;
}

.landing__calltoaction {
    padding: 15px;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 300px;
    transform: translate3d(-50%, 0%, 0);
    display: block;
}

@media (min-width: 768px) {
    .landing {
        position: relative;
    }

    .landing__container {
        padding-bottom: 50%;
    }

    .landing__header {
        top: 30%;
        min-width: 500px;
        transform: translate3d(-50%, 0%, 0);
    }

    .landing__header h1 {
        font-size: 3.5rem;
    }

    .landing__calltoaction {
        display: none;
    }
}

@media (min-width: 1024px) {
    .landing__container {
        padding-bottom: 40%;
    }
}