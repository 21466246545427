.landing {
    position: static;
    z-index: 2;
}

.landing__container {
    position: relative;
    height: 0;
    padding-bottom: 70%;
}


.landing__calltoaction {
    padding: 15px;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: 300px;
    transform: translate3d(-50%, 0%, 0);
}

@media (min-width: 768px) {
    .landing {
        position: relative;
    }

    .landing__container {
        padding-bottom: 65%;
    }
}

@media (min-width: 1024px) {
    .landing__container {
        padding-bottom: 50%;
    }
}

@media (min-width: 1078px) {
    .landing__container {
        padding-bottom: 40%;
    }
}